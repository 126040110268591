<template>
  <Component
    :is="`blueprint-${entry.blueprint?.replaceAll('_', '-')}`"
    v-if="entry"
    :page="entry"
  />
</template>

<script setup>
const route = useRoute()
const { locale, locales } = useI18n()
// useState is need to pass alternates data to LangSwitcher.vue
const alternates = useAlternates()
const { enabled: previewEnabled } = useStatamicPreviewMode()

const localeRegex = new RegExp(
  `/(${locales.value.map((l) => l.code).join('|')})(?=/|$)`
)

// remove locale prefix from current route path
let uri = route.path.replace(localeRegex, '')
// remove trailing slash
uri = uri.replace(/\/$/, '')
// set uri to root if empty
uri = uri === '' ? '/' : uri

// fetch entry
const { data, error } = await useAsyncGql({
  operation: 'Entry',
  variables: {
    uri,
    lang: locale.value,
    status: previewEnabled.value
      ? '(draft|expired|scheduled|published)'
      : 'published',
  },
})

if (error.value) {
  throw showError({
    statusCode: 500,
    message: error.value.toString(),
  })
}

// COMPUTED
const entry = computed(() => data.value?.entry || null)

// throw 404 if no entry
if (!entry.value) {
  throw showError({
    fatal: true,
    statusCode: 404,
  })
}

// set page alternate links
alternates.value = Array.isArray(entry.value?.alternates)
  ? entry.value.alternates
  : []

// META TAGS
useHead({
  ...useGenerateHead(entry.value, locale.value),
})
</script>
